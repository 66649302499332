exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aboutus-jsx": () => import("./../../../src/pages/aboutus.jsx" /* webpackChunkName: "component---src-pages-aboutus-jsx" */),
  "component---src-pages-ai-jsx": () => import("./../../../src/pages/ai.jsx" /* webpackChunkName: "component---src-pages-ai-jsx" */),
  "component---src-pages-blockchain-jsx": () => import("./../../../src/pages/blockchain.jsx" /* webpackChunkName: "component---src-pages-blockchain-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-desktop-jsx": () => import("./../../../src/pages/desktop.jsx" /* webpackChunkName: "component---src-pages-desktop-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mobile-jsx": () => import("./../../../src/pages/mobile.jsx" /* webpackChunkName: "component---src-pages-mobile-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-privacy-policy-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-privacy-policy-tsx" */),
  "component---src-pages-server-jsx": () => import("./../../../src/pages/server.jsx" /* webpackChunkName: "component---src-pages-server-jsx" */),
  "component---src-pages-terms-of-service-index-tsx": () => import("./../../../src/pages/terms-of-service/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-index-tsx" */),
  "component---src-pages-terms-of-service-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service/TermsOfService.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-terms-of-service-tsx" */),
  "component---src-pages-web-jsx": () => import("./../../../src/pages/web.jsx" /* webpackChunkName: "component---src-pages-web-jsx" */),
  "component---src-templates-article-item-jsx": () => import("./../../../src/templates/article-item.jsx" /* webpackChunkName: "component---src-templates-article-item-jsx" */),
  "component---src-templates-blog-item-jsx": () => import("./../../../src/templates/blog-item.jsx" /* webpackChunkName: "component---src-templates-blog-item-jsx" */),
  "component---src-templates-portfolio-item-jsx": () => import("./../../../src/templates/portfolio-item.jsx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx" */)
}

